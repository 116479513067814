import React from 'react'
import { combineReducers, createStore, Store } from 'redux'
import { Provider } from 'react-redux'
import { formReducer, IWithForms } from '@tf/formbuilder'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'index.scss'
import * as serviceWorker from './serviceWorker'
import appConfig from 'app.config'
import externalServices from './shared/services/externalServices'

function importBuildTarget() {
	if (process.env.REACT_APP_DESIGN_VERSION === 'selfServicePortal') {
		return import('./selfServicePortal/App')
	} else if (process.env.REACT_APP_DESIGN_VERSION === 'original') {
		return import('./original/App')
	} else {
		return Promise.reject(
			new Error(
				'No such build target: ' + process.env.REACT_APP_DESIGN_VERSION
			)
		)
	}
}

if (appConfig.cookiebotId)
	externalServices.initializeCookiebot(appConfig.cookiebotId)

if (appConfig.gtmId)
	externalServices.initializeGoogleTagManger(appConfig.gtmId)

const rootReducer = combineReducers({
	forms: formReducer,
})

const store: Store<IWithForms> = createStore(rootReducer)

importBuildTarget().then(({ default: Environment }) =>
	ReactDOM.render(
		<Provider store={store}>
			<React.StrictMode>
				<Environment />
			</React.StrictMode>
		</Provider>,
		document.getElementById('root')
	)
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
