export type LanguageType =
	| 'en'
	| 'en-EE'
	| 'en-LT'
	| 'en-LV'
	| 'ee'
	| 'lt'
	| 'no'
	| 'se'
	| 'fi'
	| 'lv'
	| 'pl'
	| 'de'
	| 'at'
	| 'dk'
	| 'es'
	| 'ru'
	| 'ru-EE'
	| 'ru-LT'
	| 'ru-LV'
	| 'it-IT'

const languages = {
	de: 'GER',
	ee: 'EST',
	en: 'ENG',
	se: 'SWE',
	fi: 'FIN',
	no: 'NOR',
	dk: 'DAN',
	es: 'SPA',
	lt: 'LIT',
	lv: 'LAT',
	ru: 'RUS',
	it: 'ITA',
}

const languagesForCountry: {
	[index: string]: {
		[key in LanguageType]?: string
	}
} = {
	de: {
		de: languages.de,
		en: languages.en,
	},
	ee: {
		ee: languages.ee,
		'ru-EE': languages.ru,
		'en-EE': languages.en,
	},
	en: {
		en: languages.en,
		de: languages.de,
		ee: languages.ee,
		se: languages.se,
		fi: languages.fi,
		no: languages.no,
		dk: languages.dk,
		lt: languages.lt,
		lv: languages.lv,
		ru: languages.ru,
	},
	se: {
		se: languages.se,
		en: languages.en,
	},
	fi: {
		fi: languages.fi,
		en: languages.en,
	},
	no: {
		no: languages.no,
		en: languages.en,
	},
	at: {
		at: languages.de,
		en: languages.en,
	},
	dk: {
		dk: languages.dk,
		en: languages.en,
	},
	es: {
		es: languages.es,
		en: languages.en,
	},
	lt: {
		lt: languages.lt,
		'ru-LT': languages.ru,
		'en-LT': languages.en,
	},
	lv: {
		lv: languages.lv,
		'ru-LV': languages.ru,
		'en-LV': languages.en,
	},
	it: {
		'it-IT': languages.it,
		en: languages.en,
	},
}

interface IAppConfig {
	country: string

	environment: string
	certificateRequesterBaseUrl: string

	system: string

	instrumentationKey: string

	authorityBaseUrl: string

	esignBaseUrl: string

	electronicIdScriptUrl: string

	afterbanksScriptUrl: string

	customerDataBaseUrl: string

	languages: {
		[key in LanguageType]?: string
	}

	gtmId: string

	cookiebotId: string

	umbracoBaseUrl: string
}

let appConfig: IAppConfig = {
	country: process.env.REACT_APP_COUNTRY as string,

	environment: process.env.REACT_APP_ENVIRONMENT as string,

	system: process.env.REACT_APP_DESIGN_VERSION as string,

	instrumentationKey: process.env
		.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATIONKEY as string,

	certificateRequesterBaseUrl:
		'https://esigningcertificaterequester.azurewebsites.net',

	authorityBaseUrl: process.env.REACT_APP_AUTHORITY_BASE_URL as string,

	esignBaseUrl: process.env.REACT_APP_ESIGN_BASE_URL as string,

	customerDataBaseUrl: process.env
		.REACT_APP_BASE_CUSTOMER_DATA_API_URL as string,

	electronicIdScriptUrl: process.env
		.REACT_APP_ELECTRONICID_SCRIPT_URL as string,

	afterbanksScriptUrl: process.env.REACT_APP_AFTERBANKS_SCRIPT_URL as string,

	languages: languagesForCountry[process.env.REACT_APP_COUNTRY as string],

	gtmId: process.env.REACT_APP_GTMID as string,

	cookiebotId: process.env.REACT_APP_COOKIEBOT_ID as string,

	umbracoBaseUrl: process.env.REACT_APP_UMBRACO_BASE_URL as string,
}

export default appConfig
